import { Component, createMemo, createSignal } from 'solid-js';
import { cn } from '~/utils/classnames';
import LabeledGroup, { LabeledGroupProps } from './LabeledGroup';

interface InputProps extends LabeledGroupProps {
  placeholder?: string;
  value?: string | number | null | undefined;
  onInput?: (e: string) => void;
  onChange?: (e: string) => void;
  onClick?: (isMouseDown: boolean) => void;
  onFocus?: (e: FocusEvent) => void;
  validationFunction?: (value: string | number) => string | undefined;
  labelClass?: string;
  inputClass?: string;
  ref?: HTMLInputElement;
  max?: string | number;
  maxLength?: string | number;
}

const LabeledTextInput: Component<InputProps> = (props) => {
  const [isTouched, setTouched] = createSignal<boolean>(false);
  const [setError] = createSignal<string | undefined>(undefined);

  const isInvalid = createMemo(() => !!props.error);
  const errorMessage = createMemo(() => {
    if (!isTouched() || !props.validationFunction) return undefined;
    return handleInputValidation(props.value, props.validationFunction, setError);
  });

  const handleInput = (value: string) => {
    props.onInput?.(value);
    setTouched(true);
  };

  const handleBlur = (): boolean => setTouched(true);

  return (
    <LabeledGroup {...props} error={errorMessage()} labelClass={props.labelClass}>
      <input
        class={cn(
          `h-10 w-full rounded-md border bg-inputbox-bg px-3 text-sm leading-10 text-black placeholder:text-auxiliary-text focus:outline-none focus:ring-1 focus:ring-primary-color`,
          props.inputClass
        )}
        classList={{
          'border-red-300': isInvalid(),
          'ring-0': isInvalid(),
        }}
        ref={props.ref}
        value={props.value || ''}
        required={props.required}
        onInput={(e) => handleInput(e.target.value)}
        onChange={(e) => props.onChange?.(e.target.value)}
        onBlur={handleBlur}
        onFocus={(e: FocusEvent) => props.onFocus?.(e)}
        type={props.type || 'text'}
        placeholder={props.placeholder}
        onMouseDown={() => props.onClick && props.onClick(true)}
        onMouseUp={() => props.onClick && props.onClick(false)}
        onClick={(e) => e.currentTarget.showPicker()}
        onKeyDown={(e) => {
          if (e.target?.getAttribute('type') !== 'date') {
            return;
          }
          e.preventDefault();
        }}
        max={props.max}
        maxlength={props.maxLength}
      />
    </LabeledGroup>
  );
};
export default LabeledTextInput;

const handleInputValidation = (
  value: string | number | null | undefined,
  validationFunction: (value: string | number) => string | undefined,
  setErrorFunction: (value: string | number | undefined) => void
) => {
  if (value) {
    const errorMessage = validationFunction(value);
    setErrorFunction(errorMessage);
    return errorMessage;
  }
};
